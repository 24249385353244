import React, { FC } from 'react'
import styled from 'styled-components'

const Paragraph = styled.p`
  color: #888e93;
  display: block;
  font-size: 22px;
  line-height: 32px;

  a {
    color: #fe3e6d;
  }

  i {
    font-style: italic;
  }

  small {
    font-size: 0.7em;
  }
`

const Text: FC = ({ children }) => <Paragraph>{children}</Paragraph>

export default Text
