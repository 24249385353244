import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Heading from '../components/Heading'
import Text from '../components/Text'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const PageRegulatory = (): JSX.Element => (
  <Layout pageType="nome-fantasia-mei" toggle={() => {}} isWebView={true}>
    <Container className='container'>
      <div>
        <Heading headingLevel='h1' size='medium'>
          Ato Declaratório Executivo COCAD nº 2
        </Heading>

        <Text>No dia 14 de novembro de 2023, foi publicado o ato normativo acima, declarando a retirada do Nome Fantasia do Cadastro Nacional de Pessoas Jurídicas, para o Microempreendedor Individual (MEI), o qual, de acordo com a determinação da Receita Federal, passou a ser  identificado apenas pela Razão Social da empresa.

        Em cumprimento ao ADE nº 2, portanto, e em conjunto com a Resolução BCB nº 96/21, para fins de cadastro e utilização da conta Cora, todos os nossos clientes MEIs também passarão a ser identificados apenas pela Razão Social, devidamente registrada na Receita Federal.

        Em outras palavras, essa será a identificação da sua empresa ao realizar ou receber transferências, bem como, ao emitir boletos por meio da sua conta PJ.</Text>
      </div>
    </Container>
  </Layout>
)

export default PageRegulatory
